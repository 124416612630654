import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import { AiFillGithub, AiFillInstagram } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";


function FindMeSection() {
  return (
    <Row>
      <Col md={12} className="home-about-social">
        <h1 className="animated-heading">FIND ME ON</h1>
        <p className="animated-subtext">
          Feel free to <span className="purple">connect</span> with me
        </p>
        <div className="social-icon">
           <a
              href="https://github.com/shivam4ukhandelwal"
              target="_blank"
              rel="noreferrer"
              className="home-social-icons"
            >
              <AiFillGithub size={30} />
            </a>
            <a
              href="https://www.linkedin.com/in/shivam-khandelwal/"
              target="_blank"
              rel="noreferrer"
              className="home-social-icons"
            >
              <FaLinkedinIn size={30} />
            </a>
            <a
              href="https://www.instagram.com/cosmocreed"
              target="_blank"
              rel="noreferrer"
              className="home-social-icons"
            >
              <AiFillInstagram size={30} />
            </a>
        </div>
      </Col>
    </Row>
  );
}


function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              I fell in love with programming and I have at least learnt
              something, I think… 🤷‍♂️
              <br />
              <br />
              I am a forward-thinking and skilled seasoned Lead Mobile
              Application Developer with over 7+ years of experience in crafting
              high-performacne, specializing in Android, iOS, cross-platform
              React-Native Development.
              <br />
              With extensive experience in building, integrating, testing, and
              supporting mobile applications, I have a proven track record of
              delivering high-quality, user-friendly apps.
              <br />
              <br />
              I am passionate about
              leveraging my expertise in...
              <br />
              <i>
                <b className="purple"> Typescript, Javascript(ES6, ES7) </b>{" "}
              </i>
              , along with{" "}
              <i>
                <b className="purple"> Java, Kotlin, Objective-C, swift</b>
              </i>
              <br />
              <br />
              My field of interests are building new and
              <i>
                {" "}
                <b className="purple"> innovative mobile applications </b>{" "}
              </i>
              , exploring the latest technologies in{" "}
              <i>
                {" "}
                <b className="purple"> mobile development </b>{" "}
              </i>
              , and continuously improving user experiences through seamless and
              efficient app design. I thrive on solving complex problems and
              bringing creative ideas to life in the digital world &nbsp;
              <br />
              <br />
              Whenever possible, I also apply my passion for developing products
              with <b className="purple">React-Native</b> ,
              <i>
                <b className="purple">
                  Android Studio, Xcode
                </b>
              </i>
              &nbsp; and sometime
              <i>
                <b className="purple"> Flutter, ReactJs and NextJs</b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <FindMeSection />
      </Container>
    </Container>
  );
}
export default Home2;
