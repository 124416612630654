import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import MacOs from "../../Assets/Icons/macos.png";
import vs from "../../Assets/Icons/vs.webp";
import figjam from "../../Assets/Icons/figjam.png";
import pm from "../../Assets/Icons/pm.png";
import studio from "../../Assets/Icons/studio.png";
import Xcode from "../../Assets/Icons/xcode.png";
import figma from "../../Assets/Icons/figma.png";
import jira from "../../Assets/Icons/jira.png";
import adobe from "../../Assets/Icons/adobe.png";
import trello from "../../Assets/Icons/trello.png";
import Git from "../../Assets/Icons/git.svg";
import bit from "../../Assets/Icons/bitbucket.png";

import "./tech.css";

const Tools = [
  { name: "macOS", icon: MacOs },
  { name: "Visual Studio Code", icon: vs },
  { name: "Android Studio", icon: studio },
  { name: "Xcode", icon: Xcode },
  { name: "FigJam", icon: figjam },
  { name: "Postman", icon: pm },
  { name: "Jira", icon: jira },
  { name: "Figma", icon: figma },
  { name: "Adobe", icon: adobe },
  { name: "Trello", icon: trello },
  { name: "Bitbucket", icon: bit },
  { name: "Git", icon: Git },
];

function ToolStack() {
  useEffect(() => {
    const techItems = document.querySelectorAll(".tech-item");
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    techItems.forEach((item) => observer.observe(item));

    return () => {
      techItems.forEach((item) => observer.unobserve(item));
    };
  }, []);

  return (
    <div className="technologies-section">
      <h1 className="project-heading">
        Professional <strong className="purple">Tools Skillset </strong>
      </h1>
      Using a combination of cutting-edge technologies and reliable open-source
      software I build user-focused, performant apps and websites for
      smartphones, tablets, and desktops.
      <br />
      <br />
      <Row style={{ justifyContent: "center", paddingBottom: "40px" }}>
        {Tools.map((tool, index) => (
          <Col key={index} xs={4} md={2} className="tech-icons">
            <div key={index} className="tech-item">
              <img src={tool.icon} alt={tool.name} className="tech-icon" />
              <p className="tech-name">{tool.name}</p>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default ToolStack;
