import React, { useRef, useState } from "react";
import { Form, Row, Col, Button } from 'react-bootstrap';
import './form.css';
import { FaLinkedin } from 'react-icons/fa';
import { DiGithub } from "react-icons/di";

function FormPage() {
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState("");
    const [result, setResult] = useState("");

    const form = useRef();

    // Function to validate email
    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    // Function to validate mobile number
    const validateMobileNumber = (number) => {
        const mobileNumberPattern = /^[0-9]{10}$/;
        return mobileNumberPattern.test(number);
    };

    const onSubmit = async (event) => {
        try {
            setSubmitting(true);
            event.preventDefault();
            const formData = new FormData(event.target);
            const name = formData.get("name");
            const email = formData.get("email");
            const number = formData.get('number');
            const message = formData.get("message");
            setResult("Sending....");
            // Check if any field is empty
            if (!name || !email || !message || !number) {
                setError("All fields are required.");
                setSubmitting(false);
                return;
            }

            // Validate email and mobile number
            if (!validateEmail(email)) {
                setError("Please enter a valid email address.");
                setSubmitting(false);
                return;
            }

            if (!validateMobileNumber(number)) {
                setError("Please enter a valid 10-digit mobile number.");
                setSubmitting(false);
                return;
            }

            setError("");
            formData.append("access_key", "08d27d4f-13a6-48ea-98f7-e5f3742c19a8");

            const response = await fetch("https://api.web3forms.com/submit", {
                method: "POST",
                body: formData
            });

            const data = await response.json();

            if (data.success) {
                setResult("Form Submitted Successfully");
                event.target.reset();
            } else {
                setResult(data.message);
            }
            setSubmitting(false);
        } catch (error) {
            setSubmitting(false);
            setResult("Something went wrong. Please try again later.");
        }
    };

    return (
        <div className="contact-content container">
            <div className="contact-form">
                <h1 className="contact-header">Contact Me</h1>
                <p className="contact-subtitle">If you have a request or question, don't hesitate to use the form.</p>
                <Form ref={form} onSubmit={onSubmit} className="form-animate">
                    <Row>
                        <Col>
                            <Form.Control type="text" placeholder="Name" name="name" required className="form-input" />
                        </Col>
                        <Col>
                            <Form.Control type="email" placeholder="E-Mail" name="email" required className="form-input" />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Control
                                type="tel"
                                placeholder="Mobile Number"
                                name="number"
                                required
                                className="form-input"
                            />
                        </Col>
                    </Row>
                    <Form.Control type="text" placeholder="Subject" name="subject" required className="form-input" />
                    <Form.Control as="textarea" placeholder="Message" name="message" required className="form-input" />
                    <Form.Group className="checkbox-view" controlId="formBasicCheckbox">
                        <Form.Check
                            id="custom-switch"
                            type="switch"
                            label="I accept the conditions set out in the privacy policy."
                            required
                            className="custom-switch-label"
                        />
                    </Form.Group>

                    <div className="contact-button">
                        <Button type="submit" disabled={submitting} style={{ width: '100%' }}>
                            {submitting ? 'Sending...' : 'Send Message!'}
                        </Button>
                    </div>
                    {error && <div className="error-message" style={{ color: 'red' }}>{error}</div>}
                    {result && <div className="success-message" style={{ color: 'green' }}>{result}</div>}
                </Form>
            </div>
            <div className="contact-social">
                <h2>Let's Get Social</h2>
                <div className="contact-social-btns">
                    <Button size="lg" className="contact-social-btn" href="https://github.com/shivam4ukhandelwal" target="_blank">
                        <DiGithub size={30} /> GitHub
                    </Button>
                    <Button size="lg" className="contact-social-btn" href="https://www.linkedin.com/in/shivam-khandelwal/" target="_blank">
                        <FaLinkedin size={30} /> LinkedIn
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default FormPage;
