import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBqUb6x8_CL0ZSjKLamGDiEh_YfQJL5uRs",
  authDomain: "shivam-khandelwal.firebaseapp.com",
  projectId: "shivam-khandelwal",
  storageBucket: "shivam-khandelwal.appspot.com",
  messagingSenderId: "912674262976",
  appId: "1:912674262976:web:e1e2b6f132f126174b4225",
  measurementId: "G-01H7FNQSK3"
};

// Initialize Firebase
export const initApp = () => {
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    console.log(app, analytics);
}