import React, { useEffect, useRef, useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { DiAndroid } from "react-icons/di";
import { IoIosStats } from "react-icons/io";
import { PiProjectorScreen } from "react-icons/pi";
import "./ProjectCard.css"; // Ensure styles are imported

const ProjectCard = ({ project, index }) => {
  const [isVisible, setIsVisible] = useState(false);
  const cardRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 } // Adjust threshold as needed
    );

    // Copy cardRef.current to a local variable to prevent stale references
    const currentCardRef = cardRef.current;
    if (currentCardRef) {
      observer.observe(currentCardRef);
    }

    return () => {
      if (currentCardRef) {
        observer.unobserve(currentCardRef);
      }
    };
  }, []);

  const isLTR = index % 2 === 0; // Adjust to alternate card directions

  return (
    <Card
      ref={cardRef}
      className={`project-card-view ${isVisible ? "animated" : ""} ${
        isLTR ? "animate-ltr" : "animate-rtl"
      }`}
    >
      <Row className={`project-card ${isLTR ? "flex-row-reverse" : ""}`}>
        <Col md={5} className="project-image-col">
          {project?.image && (
            <img
              src={project?.image}
              className="project-image"
              alt="project-image"
            />
          )}
        </Col>
        <Col md={7} className="project-details-col">
          <Card.Body className="card-body">
            <div className="project-details">
              <h5 className="project-name">{project.name}</h5>
              <Card.Text className="project-subtitle">
                {project.description}
              </Card.Text>
            </div>
            <div className="button-group">
              {project.downloadLink && (
                <Button
                  variant="primary"
                  href={project.downloadLink}
                  target="_blank"
                  className="project-button"
                >
                  <PiProjectorScreen /> App Download Page
                </Button>
              )}
              {project.androidLink && (
                <Button
                  variant="primary"
                  href={project.androidLink}
                  target="_blank"
                  className="project-button"
                >
                  <DiAndroid /> Android App
                </Button>
              )}
              {project.iOSLink && (
                <Button
                  variant="primary"
                  href={project.iOSLink}
                  target="_blank"
                  className="project-button"
                >
                  <IoIosStats /> iOS App
                </Button>
              )}
            </div>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
};

export default ProjectCard;
