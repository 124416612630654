import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import pdf from "../../Assets/resume.pdf";
import { AiOutlineDownload } from "react-icons/ai";
import './resume.css';
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function ResumeNew() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Determine scale dynamically
  const scale = width > 786 ? 1.7 : 0.6;


  const DownloadBtn = () => {
    return (
      <Row className="download-button-container">
        <Button
          variant="primary"
          href={pdf}
          target="_blank"
          className="download-button"
        >
          <AiOutlineDownload />
          &nbsp; Download CV
        </Button>
      </Row>
    );
  };

  return (
    <Container fluid className="resume-section p-3">
      <Particle />
      <DownloadBtn />
      <Row className="resume d-flex justify-content-center">
        <Document file={pdf} className="d-flex flex-column align-items-center">
          <Page pageNumber={1} scale={scale} />
          <Page pageNumber={2} scale={scale} />
        </Document>
      </Row>
      <DownloadBtn />
      <style jsx>{`
        .resume-section {
          padding: 16px;
        }
        .download-button {
          max-width: 250px;
          padding: 10px 20px;
        }
        @media (max-width: 576px) {
          .resume-section {
            padding: 8px;
          }
          .download-button {
            font-size: 14px;
          }
        }
      `}</style>
    </Container>
  );
}

export default ResumeNew;
