import React from "react";
import { Container, Row } from "react-bootstrap";
import Particle from "../Particle";
import Card from "./Card/ProjectCard";
import PROJECT_LIST from "./projectlist";
import './project.css';


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading text-center mb-4">
          My Recent <strong className="purple">Works</strong>
        </h1>
        <p className="text-center" style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row
          className="justify-content-center"
          style={{ paddingBottom: "10px"}}
        >
          {PROJECT_LIST.map(([project], index) => (
            <Card
              project={project}
              index={index}
              key={index}
              className="mb-4" // Add margin bottom for spacing
            />
          ))}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
