import React from 'react';
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import FormPage from './form';
import Particle from '../Particle';
import './contact.css';

const ContactForm = () => {
  return (
    <Container fluid className="contact-section">
        <Particle />
        <FormPage />
    </Container>
  );
};

export default ContactForm;
