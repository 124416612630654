import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { MdImportantDevices } from 'react-icons/md';
import 'bootstrap/dist/css/bootstrap.min.css';
import data from './services'; // Make sure this path is correct
import './aboutProj.css'; // Optional: add custom CSS for styling

const AboutProjects = () => {
    useEffect(() => {
        const handleScroll = () => {
          const items = document.querySelectorAll('li');
          items.forEach(item => {
            const rect = item.getBoundingClientRect();
            if (rect.top < window.innerHeight) {
              item.classList.add('visible');
            }
          });
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    
      return (
        <Container className="container-custom">
                  <br/>
          <Row>
            <Col>
            <h1 className="project-heading">
        Connect with me for <strong className="purple">Custom Mobile App Development </strong>
      </h1>
      <br/>
              <ul>
                {Object.keys(data).map((service, index) => (
                   <li key={index} className="list-item">
                   <span className="icon-container">
                     {data[service] ||  <MdImportantDevices className="icon" size={40} />}
                   </span>
                   <span className="service-text">{service}</span>
                 </li>
                  // <li key={index}>
                  //   <span className="icon">{data[service] || <MdImportantDevices />}</span> 
                  // </li>
                ))}
              </ul>
            </Col>
          </Row>
        </Container>
      )
};

export default AboutProjects;